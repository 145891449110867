
import { defineComponent, onBeforeMount, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ElMessage } from 'element-plus'
import { indexApi } from '@/api/modules/index'

export default defineComponent({
  name: 'feedbackIndex',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    // 获取全局变量
    const { proxy } = getCurrentInstance() as any

    // 获取refs
    const addOrEditFormRef = ref()

    // onBeforeMount(() => {})

    onMounted(() => {
      // console.log('decryptData', data.decryptData)
      data.addOrEditForm.name = data.decryptData.userName || ''
      data.addOrEditForm.mobile = data.decryptData.userMobile || ''
    })

    const data = reactive({
      encryptData: store.state.app.encryptData,
      decryptData: store.state.app.decryptData,
      selectedItem: 2,
      addOrEditForm: {
        title: '',
        content: '',
        name: '',
        mobile: '',
        pic: []
      },
      addOrEditRules: {
        title: [{ required: true, message: '请输入主题', trigger: 'blur' }],
        content: [{ required: true, message: '请输入意见或建议', trigger: 'blur' }]
        // name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        // mobile: [
        //   { required: true, message: '请输入手机号码', trigger: 'blur' },
        //   { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
        //   {
        //     pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
        //     message: '请输入正确的手机号码'
        //   }
        // ]
      },

      // 提交
      onSubmit () {
        console.log('data.addOrEditForm', data.addOrEditForm)
        addOrEditFormRef.value.validate(async (valid: any) => {
          if (!valid) return
          const form = proxy.$globalFun.deepClone(data.addOrEditForm)
          form.pic = JSON.stringify(form.pic)

          await indexApi.opinionSave(form)
          ElMessage.success('意见反馈已提交')
          router.push(`/index?dataItem=${data.encryptData}`)
        })
      }
    })

    return {
      ...toRefs(data),
      addOrEditFormRef
    }
  }
})
